<script>
  export let label = 'button'
  export let color = 'green'
  export let className = ''
  export let id = undefined
  export let disabled = false
  export let icon = false
</script>

<style>
  button {
    margin: 0;
    border: 0;
    border-radius: 999vh;
    box-shadow: inset 0 -0.12em 0.07em 0.02em rgba(0, 0, 0, 0.16);
    cursor: pointer;
    font-size: var(--button-size);
    font-weight: var(--regular-weight);
    padding: 0.19em 0.666em 0.38em 0.666em;
  }

  button.icon {
    height: calc(var(--button-size) * 1.76);
    width: calc(var(--button-size) * 1.76);
  }

  button.green {
    background-color: var(--green);
  }

  button.blue {
    background-color: var(--blue);
  }

  button:not(disabled):active {
    transform: translateY(2px);
    box-shadow: inset 0 -0.04em 0.07em 0.02em rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  }
</style>

<button
  {id}
  class:icon
  class:green={color === 'green'}
  class:blue={color === 'blue'}
  class={className}
  on:click
  {disabled}>
  <slot>{label}</slot>
</button>
