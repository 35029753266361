<script>
  import Kampos from 'kampos/src/kampos'
  import { onMount, createEventDispatcher, onDestroy, getContext } from 'svelte'
  import { handScale } from '../stores'
  import { FPS } from '../constants'

  import transparentVideo from '../utils/video-effect'
  import request from '../utils/request'

  const retina = true // TODO: this needs to be display dependent
  const dispatch = createEventDispatcher()
  const gui = getContext('gui')

  export let file
  export let origin
  export let frames = {}
  export let playing = false

  let canvas = document.querySelector('#canvas')
  let media, canvasContainer
  let instance
  let time = 0
  let frame
  let xhr

  $: retina, init()
  $: events = new Map(Array.from(Object.entries(frames), (entry) => [entry[1], entry[0]]))

  onMount(() => {
    canvasContainer.appendChild(canvas)
    instance = new Kampos({ target: canvas, effects: [transparentVideo()] })

    xhr = request(file, {
      onload: function () {
        if (this.status === 200) {
          const blob = this.response
          const url = window.URL.createObjectURL(blob)
          media.src = url
          media.onerror = console.error
          media.load()
        }
      },
      onerror: console.error,
    })
  })

  const setHandSize = () => {
    if (!media) return
    const width = media.videoWidth / 2
    const height = media.videoHeight
    const inverted = 1 / $handScale
    const elWidth = retina ? width / inverted : width
    const elHeight = retina ? height / inverted : height
    canvas.style.width = `${elWidth}px`
    const offsetX = (origin[0] / 100) * elWidth
    const offsetY = (origin[1] / 100) * elHeight
    canvasContainer.style.transform = `translate(-${offsetX}px, -${offsetY}px)`
  }

  const unsub = handScale.subscribe(() => {
    setHandSize()
  })

  const init = () => {
    if (!media) return
    const width = media.videoWidth / 2
    const height = media.videoHeight

    media.currentTime = 0
    instance.setSource({ media, width, height })
    setHandSize()
    play()
  }

  let raf
  const checkTime = () => {
    time = media.currentTime
    const newFrame = Math.floor(time * FPS) + 1
    if (newFrame !== frame) {
      frame = newFrame
      dispatch('frame', frame)
      const event = events.get(newFrame)
      if (event) dispatch('event', event)
    }
    raf = window.requestAnimationFrame(checkTime)
  }

  export const pause = () => {
    media.pause()
    dispatch('event', 'pause')
  }

  export const stop = (isCancelled = false) => {
    window.cancelAnimationFrame(raf)
    if (!playing) return

    playing = false
    media.pause()
    instance.stop()
    dispatch('event', 'stop')
    dispatch('end', isCancelled)
  }

  export const cancel = () => {
    stop(true)
  }

  export const play = (time) => {
    window.cancelAnimationFrame(raf)
    checkTime()

    playing = true
    if (time === 0) media.currentTime = 0

    canvas.style.display = 'block'

    media.play()
    instance.play()

    if (media.currentTime === 0) {
      dispatch('event', 'play')
    }
  }

  onDestroy(() => {
    canvas.style.display = 'none'
    document.body.appendChild(canvas)
    if (instance) instance.destroy()
    if (xhr) xhr.abort()
    window.cancelAnimationFrame(raf)
    unsub()
  })
</script>

<style>
  .container {
    overflow: hidden;
    line-height: 0;
  }
  .video-container {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
  }
  .canvas-container.gui {
    border: 1px solid red;
  }
</style>

<div class="container">
  <div class="video-container">
    <video bind:this={media} muted playsinline on:loadeddata={init} on:ended={stop} />
  </div>
</div>
<div class:gui class="canvas-container" bind:this={canvasContainer} />
