<script>
  import Controls from '../components/Controls.svelte'
  import Info from '../components/Info.svelte'
  import MuteButton from '../components/MuteButton.svelte'
  import Scenario from '../components/Scenario.svelte'
  import Button from '../components/ui/Button.svelte'
  import { showInfo } from '../stores'

  const onBuy = () => {
    window.location = 'https://shop.studiomoniker.com/product/gestures-typeface'
  }
</script>

<style>
  .buttons {
    position: absolute;
    top: 1.6vw;
    right: 1.6vw;
    display: flex;
    z-index: 999;
  }

  .buttons :global(button + button) {
    margin-left: 2vw;
  }

  @media (max-width: 768px) {
    .buttons {
      top: 3vw;
      right: 3vw;
    }

    .buttons :global(button + button) {
      margin-left: 4vw;
    }
  }

  @media (min-width: 768px) {
    .buttons :global(#mute-btn) {
      margin-left: 4vw;
    }
  }
</style>

<div class="buttons">
  <Button id="buy-btn" label="buy" color="blue" on:click={onBuy} />
  <MuteButton />
  <Button id="info-btn" label="info" color="green" on:click={() => ($showInfo = true)} />
</div>

{#if $showInfo}
  <Info on:close={() => ($showInfo = false)} />
{/if}

<Controls />

<Scenario />
