/**
 * A big part of this component is toggling alt characters in the text
 * We do this by view the text as an array of characters.
 * For every index of the array we determine if it's character is "toggleable"
 * (Basically means if it's <br/> or not)
 * To keep track of state we have 2 arrays ('used' and 'unused') containing indices of what char to toggle.
 * When we need to add an alt char we move an unused index from the unused array to the used array (and vice versa)
 * To create the text we loop over all the parts/characters and
 * replace every character by an alt version if the index is in the used array.
 */

export const LETTERS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
export const ALTS = LETTERS.map((l, idx) =>
  String.fromCharCode(parseInt(`0xe${idx.toString().padStart(3, '0')}`)),
)
export const ALT_MAPPING = LETTERS.reduce((acc, cur, idx) => {
  acc[cur] = ALTS[idx]
  return acc
}, {})

const getAlt = (char) => {
  const alt = ALT_MAPPING[char]
  return alt ? alt : char
}

const getNormal = (char) => {
  const index = ALTS.indexOf(char)
  return index >= 0 ? LETTERS[index] : char
}

const isAlt = (char) => {
  return ALTS.includes(char)
}

// const numAltsInText = (text) => {
//   const matches = text.match(/[\uE000-\uE051]/gi)
//   return matches ? matches.length : 0
// }

// const numAltOptionsInText = (text) => {
//   const matches = text.replace(/(<p>|<\/p>|<br\/>)/gi, '').match(/[a-zA-Z]/gi)
//   return matches ? matches.length : 0
// }

class AltCharacterToggler {
  constructor(html) {
    if (html) {
      this.fromHTML(html)
    }
  }

  fromHTML(html) {
    this.originalHTML = html

    // first we split the text up in characters
    // this will result in an array with characters, including alt chars (with <i>) and breaks (<br/>)
    this.parts = html
      .replace(/&nbsp;/gi, ' ')
      .match(/((<span class="case">)|(<\/span>)|(<p>)|(<\/p>)|(<br\/>)|(<br>)|(\S)|(\s))/gi)

    // create an array of plain characters, but keep the <br/> and spaces
    this.plainParts = this.parts.map(getNormal)
    this.plainText = this.plainParts.filter((p) => p.length === 1).join('')

    const hypens = ['(', ')', '[', ']', '{', '}', '/', '\\', ':', ';', '·', '-', '–', '—', '•']
    for (let i = 0; i < this.plainParts.length; i++) {
      const part = this.plainParts[i]
      if (hypens.includes(part)) {
        const prev = i > 0 && this.plainParts[i - 1]
        const next = i < this.plainParts.length && this.plainParts[i + 1]
        if (
          prev &&
          next &&
          prev.length === 1 &&
          next.length === 1 &&
          prev === prev.toUpperCase() &&
          next === next.toUpperCase()
        ) {
          this.plainParts[i] = `<span class="case">${part}</span>`
        }
      }
    }

    // create the array of possible indices (filtering out <br/> and spaces)
    this.possibleIndices = this.parts
      .map((char, index) => (LETTERS.includes(getNormal(char)) ? index : false))
      .filter((index) => index !== false)

    this.init()
  }

  init() {
    // create the array of indices that have alt chars in the original text
    this.used = this.possibleIndices
      .map((index) => (isAlt(this.parts[index]) ? index : false))
      .filter((index) => index !== false)

    this.unused = this.possibleIndices.filter((index) => !this.used.includes(index))
    // amount of characters that can be toggled
    this.length = this.possibleIndices.length
    // original number of alt characters in the text
    this.originalNumAlt = this.used.length
    // this.text = this.parts.join('')

    this.percentage = this.used.length / this.possibleIndices.length
    this.amount = Math.round(this.percentage * this.possibleIndices.length)
  }

  setPercentage(percentage) {
    if (!this.possibleIndices) return
    this.percentage = percentage
    const amount = Math.round(percentage * this.possibleIndices.length)
    this.setAmount(amount)
  }

  setAmount(amount) {
    if (!this.possibleIndices) return
    if (amount < 0) amount = 0
    if (amount > this.possibleIndices.length) amount = this.possibleIndices.length

    // calculate how many characters we need to change
    const diff = amount - this.amount
    if (diff < 0) {
      // for the amount of characters we need to remove
      for (let i = 0; i < Math.abs(diff); i++) {
        // pick/remove random index from used array
        const remove = this.used.splice(Math.floor(Math.random() * this.used.length), 1).pop()
        // add it to the unused array
        this.unused.push(remove)
      }
    } else {
      // for the amount of characters we need to add
      for (let i = 0; i < Math.abs(diff); i++) {
        // pick/remove random index from unused array
        const add = this.unused.splice(Math.floor(Math.random() * this.unused.length), 1).pop()
        // add it to the used array
        this.used.push(add)
      }
    }

    this.amount = amount
  }

  get text() {
    if (!this.possibleIndices) return ''
    const text = this.plainParts
      .map((char, index) => (this.used.includes(index) ? getAlt(char) : char))
      .join('')

    return text
  }
}

export default AltCharacterToggler
