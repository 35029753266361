const texts = [
  {
    "Size": 0.382,
    "Text": "<p>INT. IDENITY TRAINING FACILITY<span class=\"case\">–</span>DAY</p>",
    "Weight": 0,
    "Dark": false,
    "Alt": 0.022
  },
  {
    "Size": 0.15,
    "Text": "<p>A brightly lit hallway with a green rubber floor.</p><p>ANATMA and HE REATFISHR are exercising in a training facility. he reatfishe is balancing on a big ice-blue circus ball, taking many tiny steps not to fall off. He tries to reach ‘other’ states of mind.</p><p>Antomi is wearing skin-coloured leggings with hair printed on them and is jumping with a jumping rope, switching from one leg to the other. She is breathing heavily. Hassling with her body.</p>",
    "Weight": 0,
    "Dark": false,
    "Alt": 0.028
  },
  {
    "Size": 0.189,
    "Text": "<p>When talking to each other, they use electronically-enhanced hand fans. Wenever the fans are held in frot of their mouths, they reformulate in realtime everything they say nto harmless, frictionless versions of what they are saying. </p><p>Before they onl used it whenever they were angry or emotional, but lately they use it almost all day. They feel less insecure that way…</p>",
    "Weight": 0,
    "Dark": true,
    "Alt": 0.013
  },
  {
    "Size": 0.305,
    "Text": "<p>ANATMA (screams)</p><p>oooch, dan<br/>I cant believe another one is gone!</p>",
    "Weight": 1,
    "Dark": false,
    "Alt": 0.163
  },
  {
    "Size": 0.382,
    "Text": "<p>Antomi has dropped her had fa nd is i great paic.</p>",
    "Weight": 0.643,
    "Dark": false,
    "Alt": 0.184
  },
  {
    "Size": 0.305,
    "Text": "<p>ANATMA (hysterical)</p><p>Lok at my ucking hnd!!!!<br/>AHHH ah</p>",
    "Weight": 0,
    "Dark": true,
    "Alt": 0.237
  },
  {
    "Size": 0.227,
    "Text": "<p>he reatfishe is hummng and rasing his arms up n the ar. e slowly bings down one hand and moves his fan in frot of his mouth.</p>",
    "Weight": 1,
    "Dark": false,
    "Alt": 0.104
  },
  {
    "Size": 0.189,
    "Text": "<p>HE REATFISHR (calm)</p><p>Hm... hmm... Di I hear you correctly? id you just start talking fanless? Would you be so ind as t hold our fan when you talk to me? I really regret the stat of affairs.</p>",
    "Weight": 0,
    "Dark": false,
    "Alt": 0.115
  },
  {
    "Size": 0.227,
    "Text": "<p>V.O.<br/>Actally he reatfishe sad smething log the lines o… TF? Stop sceaing like that! Why do ou alays nterrupt my raiing? Hold your fcking fan i front of yur fae when you re talking to me!</p>",
    "Weight": 0.643,
    "Dark": false,
    "Alt": 0.154
  },
  {
    "Size": 0.382,
    "Text": "<p>he reatfishe continues to hm. Antomi tops jumping and stares at er hads.</p>",
    "Weight": 1,
    "Dark": false,
    "Alt": 0.155
  },
  {
    "Size": 0.227,
    "Text": "<p>ANATMA (hysterical)</p><p>Didn't you hea? ou didn't hear me! I kne you didn't hear me anmore, what’ wrong?  just lost another finger. This is insae!</p>",
    "Weight": 0,
    "Dark": true,
    "Alt": 0.086
  },
  {
    "Size": 0.227,
    "Text": "<p>he reatfishe gestures to Antomi urgetly to hold up her hand fa. Antomi oves her fa in frot o her outh.</p>",
    "Weight": 0,
    "Dark": false,
    "Alt": 0.175
  },
  {
    "Size": 0.15,
    "Text": "<p>ANATMA (optmstc and calm)</p><p> really regret nterruptng your tranng,  just wanted to nform you as a matter of urgency that 've lost one of my fngers, and whle ths may be the result of my transton,  stll feel really worred about t.</p>",
    "Weight": 1,
    "Dark": false,
    "Alt": 0.126
  },
  {
    "Size": 0.305,
    "Text": "<p>he reatfishe stops his meitative eercise, e is a bit annoyed but aso oied.</p>",
    "Weight": 0.286,
    "Dark": false,
    "Alt": 0.169
  },
  {
    "Size": 0.227,
    "Text": "<p>HE REATFISHR (calm)</p><p>Wel, I trly lament the sitation and wold strongly advise yo to slow down a bit. Yo can't force birdship. Yor mind needs to keep p with yor body.</p>",
    "Weight": 0.643,
    "Dark": false,
    "Alt": 0.095
  },
  {
    "Size": 0.382,
    "Text": "<p>Antomi throws her fn in the corner of the spce.</p>",
    "Weight": 0.286,
    "Dark": true,
    "Alt": 0.108
  },
  {
    "Size": 0.189,
    "Text": "<p>ANATMA (ecite<span class=\"case\">)</span></p><p>ut I'm almost there! Nbody can catch m, follow me, label m, share me or identify m. I just want to descend from abov and oi the party at th center of the danc floor.</p>",
    "Weight": 0,
    "Dark": false,
    "Alt": 0.092
  },
  {
    "Size": 0.575,
    "Text": "<p>t’s ilent fo a momet.</p>",
    "Weight": 1,
    "Dark": false,
    "Alt": 0.267
  },
  {
    "Size": 0.382,
    "Text": "<p>ANATMA (soft)</p><p> fel so eolved, ou o…</p>",
    "Weight": 0,
    "Dark": true,
    "Alt": 0.375
  },
  {
    "Size": 0.305,
    "Text": "<p>he reatfishe is unable to express hiself via his han f<span class=\"case\">;</span>  s makig weird head gestures n saing words over and ove again.</p>",
    "Weight": 1,
    "Dark": false,
    "Alt": 0.136
  },
  {
    "Size": 0.189,
    "Text": "<p>HE REATFISHR <span class=\"case\">(</span>ooonos)</p><p>e hav an authenti relationshp and wor in  safe place fo inspiraton and epresion.</p>",
    "Weight": 0.643,
    "Dark": true,
    "Alt": 0.172
  },
  {
    "Size": 0.15,
    "Text": "<p>Antomi s left it 5 finers o on and, 3 on te othe han. She maes hetic houlde mveens ad prctises som ird sods. Her irdsi a evelopd far beyod the eanngfl and logicl. </p><p>he reatfishe is coviced that the hand fans are working perfectly and that his relationship with Antomi has iproved greatly. He does not always undestand her behaviour, but hen she speaks he listens to her thoughts, expressed in an understandable and inoffensive way. e feels, finally, that he can now understand her on another level. The dirt and hassle of counication is cleaned up. That puts him at ease and encourages his rst.</p>",
    "Weight": 0,
    "Dark": false,
    "Alt": 0.11
  },
  {
    "Size": 0.382,
    "Text": "<p>Antomi s flppg wt bnt ees.</p>",
    "Weight": 1,
    "Dark": false,
    "Alt": 0.55
  },
  {
    "Size": 0.382,
    "Text": "<p>ANATMA (effortless)</p><p>rr, lib lib, zirrp zirp, hii iiiiiuiii</p>",
    "Weight": 0.286,
    "Dark": false,
    "Alt": 0.511
  },
  {
    "Size": 0.305,
    "Text": "<p>he reatfishe stads stil, is eyes alf-shut nd loking upards. e lowes th fan frm his out, his hees tur re.</p>",
    "Weight": 0,
    "Dark": false,
    "Alt": 0.253
  },
  {
    "Size": 0.305,
    "Text": "<p>HE REATFISHR <span class=\"case\">(</span>sec<span class=\"case\">)</span> </p><p>l… plo… r… vl... vl...</p>",
    "Weight": 0,
    "Dark": true,
    "Alt": 0.263
  },
  {
    "Size": 0.575,
    "Text": "<p>The nd</p>",
    "Weight": 0.286,
    "Dark": true,
    "Alt": 0.2
  }
]
export default texts