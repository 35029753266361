<script>
  import { onDestroy, getContext, createEventDispatcher } from 'svelte'

  import * as stores from '../stores'

  const dispatch = createEventDispatcher()
  const interrupted = getContext('interrupted')
  let times = {}
  let unsubs = []

  const triggers = [
    'fontSize',
    'fontWeight',
    'fontAltChars',
    'sliderProp',
    'darkMode',
    'textSlideIndex',
  ]

  Object.keys(stores).forEach((prop) => {
    if (triggers.includes(prop)) {
      times[prop] = Date.now()
      const unsub = stores[prop].subscribe((value) => {
        times[prop] = Date.now()
        dispatch('change', { prop, value })
      })
      unsubs.push(unsub)
    }
  })

  onDestroy(() => {
    unsubs.forEach((u) => u())
  })
</script>

<!--<style>
  .debug {
    position: absolute;
    top: 100px;
    left: 0;
  }
</style><div class="debug">
  {$interrupted}<br />
  {#each Object.keys(times) as name}{name}: {times[name]}<br />{/each}
</div> -->
