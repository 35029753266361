<script>
  import AudioPlayer from '../utils/AudioPlayer'

  import Button from './ui/Button.svelte'

  let muted = AudioPlayer.muted

  const toggleMute = () => {
    if (muted) {
      AudioPlayer.fadeIn()
    } else {
      AudioPlayer.fadeOut()
    }
    muted = !muted
    AudioPlayer.muted = muted

    try {
      window.localStorage.setItem('gestures-muted', muted ? 'true' : 'false')
    } catch (error) {
      //
    }
  }
</script>

<style>
  svg {
    position: relative;
    left: calc(var(--button-size) * -0.3);
    top: calc(var(--button-size) * 0.07);
    width: calc(var(--button-size) * 1.05);
  }
</style>

<Button icon id="mute-btn" on:click={toggleMute}>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 21.9 18"
    style="enable-background:new 0 0 21.9 18;"
    xml:space="preserve">
    {#if muted}
      <polygon
        points="21.9,5.9 21.2,5.1 18.2,8.2 15.1,5.1 14.4,5.9 17.4,8.9 14.4,11.9 15.1,12.6 18.2,9.6 21.2,12.6 21.9,11.9 
18.9,8.9 	" />
    {:else}
      <g>
        <path
          d="M18.7,9L18.7,9c0-2.4-1.9-4.3-4.3-4.3v0.8C16.4,5.4,18,7,18,9c0,1.9-1.6,3.5-3.5,3.5v0.8C16.8,13.3,18.7,11.3,18.7,9z" />
        <path
          d="M14.4,10.4v0.8c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2v0.8c0.8,0,1.4,0.6,1.4,1.4C15.9,9.7,15.2,10.4,14.4,10.4z" />
        <path
          d="M14.4,2.6v0.8C17.5,3.4,20,5.9,20,9s-2.5,5.6-5.6,5.6v0.8c3.5,0,6.4-2.9,6.4-6.4C20.8,5.4,18,2.6,14.4,2.6z" />
      </g>
    {/if}
    <path
      d="M0,5v8h2.8l9.3,5V0L2.8,5H0z M1.5,6.5h1.3v5H1.5V6.5z M4.3,5.9l6.4-3.4v13l-6.4-3.4C4.3,12.1,4.3,5.9,4.3,5.9z" />
  </svg>
</Button>
