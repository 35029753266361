<script context="module">
  export const frames = {
    return: 36,
    end: 76,
  }

  export const origin = [62, 44]
</script>

<script>
  import Animation from '../gestures/Animation.svelte'

  export let type, states
</script>

<Animation file="videos/1920/SwipeUp.mp4" {type} {states} {frames} {origin} on:event on:end />
