import { get } from 'svelte/store'

import scenes from '../scenes'
import * as stores from '../stores'
import texts from '../texts'

const properties = ['darkMode', 'fontSize', 'fontWeight', 'fontAltChars']

class ResetScenario {
  checkProperty(prop, val) {
    const { Size, Weight, Alt, Dark } = texts[get(stores.textSlideIndex)]
    const values = {
      fontSize: Size,
      fontWeight: Weight,
      fontAltChars: Alt,
      darkMode: Dark,
    }
    let original = values[prop]
    let changed = {}
    if ((prop === 'darkMode' && val !== Dark) || Math.abs(val - original) > 0.001) {
      changed[prop] = original
    }
    return changed
  }

  run() {
    const change = properties
      .map((prop) => this.checkProperty(prop, get(stores[prop])))
      .filter((c) => Object.keys(c).length > 0)
      .shift()

    if (!change) return

    let [prop, value] = Object.entries(change)[0]
    if (change.hasOwnProperty('darkMode')) {
      return {
        type: 'reset',
        component: scenes.TapDarkMode,
      }
    } else {
      if (prop === get(stores.sliderProp)) {
        return {
          type: 'reset',
          component: scenes.MoveSlider,
          props: { to: value },
        }
      } else {
        const components = {
          fontSize: scenes.TapSizeLabel,
          fontWeight: scenes.TapWeightLabel,
          fontAltChars: scenes.TapAltLabel,
        }
        return { type: 'reset', component: components[prop] }
      }
    }
  }
}

export default ResetScenario
