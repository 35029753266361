function transparentVideo() {
  return {
    vertex: {
      uniform: {
        u_texOffset: 'vec2',
      },
      main: 'v_texAlphaCoord = v_texCoord + u_texOffset;',
    },
    fragment: {
      main: `
          alpha *= dot(lumcoeff, texture2D(u_source, v_texAlphaCoord).rgb);

          // transform alpha values from sRGB source to linearRGB - this cleans up the rim from the result
          float linear;\
          if (alpha <= 0.04045) {
              linear = alpha / 12.92;
          }
          else {
              linear = pow((alpha + 0.055) / 1.055, 2.4);
          }
          
          alpha = linear;

          if (alpha < 0.04) {
              alpha = 0.0;
          }
          else if (alpha > 0.96) {
              alpha = 1.0;
          }`,
    },
    get disabled() {
      return !this.uniforms[0].data[0]
    },
    set disabled(b) {
      this.uniforms[0].data[0] = +!b
    },
    varying: {
      v_texAlphaCoord: 'vec2',
    },
    uniforms: [
      {
        name: 'u_texOffset',
        type: 'f',
        data: [0.5, 0.0],
      },
    ],
    attributes: [
      {
        name: 'a_texCoord',
        data: new Float32Array([0.0, 0.0, 0.0, 1.0, 0.5, 0.0, 0.5, 1.0]),
        size: 2,
        type: 'FLOAT',
      },
    ],
  }
}

export default transparentVideo
