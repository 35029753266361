<script>
  import WebFont from 'webfontloader'

  import Home from './routes/Home.svelte'
  import { darkMode } from './stores'
  import { onMount } from 'svelte'

  let root
  let fontLoaded = false

  onMount(() => {
    root = document.documentElement
    onResize()
  })

  WebFont.load({
    classes: false,
    custom: {
      families: ['Gestures'],
      urls: ['./global.css'],
    },
    active: () => {
      document.querySelector('#info-text').style.display = 'none'
      fontLoaded = true
    },
  })

  $: {
    if (root && $darkMode) root.classList.add('dark')
    if (root && !$darkMode) root.classList.remove('dark')
  }

  const onResize = () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
</script>

<style>
  :global(:root) {
    --green: #64e40a;
    --blue: #2587ff;
    --white: #f7f7f7;
    --black: black;
    --text-color: var(--black);
    --background-color: var(--white);

    --regular-weight: 80;
    --medium-weight: 100;
    --bold-weight: 125;
    --heavy-weight: 150;

    --button-size: 1.98vw;
    --label-size: 1.75vw;

    font-weight: 80;
  }

  :global(:root.dark) {
    --text-color: var(--white);
    --background-color: var(--black);
  }

  :global(.regular) {
    font-variation-settings: 'wght' var(--regular-weight);
  }

  :global(.medium) {
    font-variation-settings: 'wght' var(--medium-weight);
  }

  :global(.bold) {
    font-variation-settings: 'wght' var(--bold-weight);
  }

  :global(.heavy) {
    font-variation-settings: 'wght' var(--heavy-weight);
  }

  @media (max-width: 768px) {
    :global(:root) {
      --button-size: 5.2vw;
      --label-size: 4.25vw;
    }
  }

  :global(main.loading-font > *) {
    opacity: 0;
  }
</style>

<svelte:window on:resize={onResize} />

<main class:loading-font={!fontLoaded}>
  {#if fontLoaded}
    <Home />
  {/if}
</main>

<canvas id="canvas" />
