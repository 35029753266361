import TapDarkMode from './TapDarkMode.svelte'
import TapSizeLabel from './TapSizeLabel.svelte'
import TapWeightLabel from './TapWeightLabel.svelte'
import TapAltLabel from './TapAltLabel.svelte'
import MoveSlider from './MoveSlider.svelte'
import SwipeUp from './SwipeUp.svelte'
import SwipeDown from './SwipeDown.svelte'
import SwipeLeft from './SwipeLeft.svelte'
import SwipeRight from './SwipeRight.svelte'
import RockOn from './RockOn.svelte'
import LoveYou from './LoveYou.svelte'
import Okay from './Okay.svelte'
import ThumbsUp from './ThumbsUp.svelte'
import RaisedFist from './RaisedFist.svelte'
import Victory from './Victory.svelte'
import VulcanSalute from './VulcanSalute.svelte'
import PointUpAndBounce from './PointUpAndBounce.svelte'

export default {
  MoveSlider,
  TapDarkMode,
  TapSizeLabel,
  TapWeightLabel,
  TapAltLabel,
  SwipeUp,
  SwipeDown,
  SwipeLeft,
  SwipeRight,
  RockOn,
  LoveYou,
  Okay,
  ThumbsUp,
  RaisedFist,
  Victory,
  VulcanSalute,
  PointUpAndBounce,
}
