<script>
  import { onDestroy, setContext } from 'svelte'
  import { writable } from 'svelte/store'
  import debounce from 'lodash/debounce'

  import SwipeScenario from './SwipeScenario'
  import ResetScenario from './ResetScenario'
  import TextSlides from './TextSlides.svelte'
  import ScenarioValueListener from './ScenarioValueListener.svelte'
  import AudioPlayer from '../utils/AudioPlayer'
  import { textSlideIndex, editing, edited, showInfo } from '../stores'
  import { requestTimeout, clearRequestTimeout } from '../utils/requestTimeout'
  import EmojiScenes from './EmojiScenes'
  import texts from '../texts'
  import scenes from '../scenes'

  const getClassName = (classVariable) => classVariable.prototype.constructor.name

  let scenario = []
  let isTouching = false
  let isTransitioning = false
  const played = {}

  $: isPlaying = scenario.filter((scene) => scene.started).length > 0
  const nextSlideAllowed = () =>
    !$showInfo &&
    !AudioPlayer.isLoading &&
    !AudioPlayer.isPlaying &&
    !$editing &&
    !$interrupted &&
    !isTouching &&
    scenario.length === 0 &&
    !isTransitioning

  const interrupted = writable(false)
  setContext('interrupted', interrupted)

  let swipeTimer
  const addScene = (scene) => {
    if ($showInfo || isPlaying || isTouching || scenario.length > 0 || $editing) return
    if (scene && scene.background && scenario.filter((s) => s.background).length > 0) return // only 1 swipe animation at a time
    if (scene) {
      if (swipeTimer) clearRequestTimeout(swipeTimer)
      scenario = [...scenario, scene]
    }
  }

  // slowly ramp up the delays over time
  const delays = [500, 500, 500, 1000, 1000, 1000, 2000, 2000, 2000, 3000, 4000, 5000]
  let delayIndex = 0
  const playReset = debounce(() => {
    // if we're allowed to go the next slide we don't need to reset all text properties
    // edited can be 0!!!
    if (nextSlideAllowed() || (!$editing && $edited !== false)) {
      if (swipeTimer) clearRequestTimeout(swipeTimer)
      swipeTimer = requestTimeout(
        () => {
          playSwipe()
        },
        $edited !== false ? 0 : 2500, // edited can be 0!!!
      )
    } else {
      if (swipeTimer) clearRequestTimeout(swipeTimer)
      swipeTimer = requestTimeout(() => {
        addScene(reset.run())
      }, delays[delayIndex])
    }
  }, 250)

  const playSwipe = () => {
    if (nextSlideAllowed()) {
      if ($textSlideIndex >= texts.length - 1) {
        const enoughTimePast = played[getClassName(scenes.PointUpAndBounce)]
          ? Date.now() - played[getClassName(scenes.PointUpAndBounce)] > 5000
          : true
        if (enoughTimePast) addScene({ component: scenes.PointUpAndBounce })
      } else {
        addScene(swipe.next())
      }
    }
  }

  const playEmoji = debounce(() => {
    if ($editing && $edited) {
      // edited can be 0, don't play an emoji when $edited = 0
      scenario = scenario.filter((c) => c.type !== 'emoji')
      scenario = [...scenario, EmojiScenes()]
    }
  }, 1000)

  const onSceneStart = (scene) => {
    const sceneIndex = scenario.indexOf(scene)
    scenario[sceneIndex].started = true
    scenario = [...scenario]
  }

  const onSceneEnd = (scene) => {
    if (scene.type === 'reset' && scene.interrupted !== true) {
      delayIndex++
      if (delayIndex >= delays.length) delayIndex = delays.length - 1
    }

    played[getClassName(scene.component)] = Date.now()

    $interrupted = false
    scenario = scenario.filter((c) => c !== scene)
    if (!isTouching) playReset()
  }

  const onSlideStart = () => {
    isTransitioning = true
    $editing = false
    $edited = false
  }

  const onSlideEnd = async () => {
    if (!AudioPlayer.isPlaying && AudioPlayer.number !== $textSlideIndex + 1 && $edited === false) {
      try {
        await AudioPlayer.play($textSlideIndex + 1)
      } catch (error) {
        console.error(error)
      }
    }
    isTransitioning = false
  }

  const onValueChange = ({ detail: change }) => {
    if ($editing) {
      playEmoji()
    } else if ($edited !== false) {
      // $edited can be 0!!!
      if (swipeTimer) clearRequestTimeout(swipeTimer)
      playEmoji()
      swipeTimer = requestTimeout(() => {
        playSwipe()
      }, 5000)
    } else {
      if (isTouching) return
      const { prop } = change
      if (['fontSize', 'fontWeight', 'fontAltChars', 'darkMode'].includes(prop)) {
        playReset()
      }
      playSwipe()
    }
  }

  const touchStart = () => {
    isTouching = true
    $interrupted = true

    // removing all not-started/loaded videos
    scenario = scenario.filter((scene) => {
      return !!scene.started
    })

    scenario.forEach((scene) => (scene.interrupted = true))
  }

  const touchEnd = () => {
    isTouching = false
    $interrupted = false

    if (scenario.length === 0) playReset()
  }

  const swipe = new SwipeScenario()
  const reset = new ResetScenario()

  AudioPlayer.onEnd = playSwipe
  AudioPlayer.onError = console.error

  const unsubInfo = showInfo.subscribe((visible) => {
    if (visible) {
      AudioPlayer.pause()
      $interrupted = true
    } else {
      AudioPlayer.resume()
      $interrupted = false
    }
  })

  let init = false
  const unsubEditing = editing.subscribe((isEditing) => {
    if (init && !isEditing) playSwipe()
    init = true
  })

  onDestroy(() => {
    unsubInfo()
    unsubEditing()
  })
</script>

<svelte:window
  on:mousedown={touchStart}
  on:mouseup={touchEnd}
  on:touchstart={touchStart}
  on:touchend={touchEnd}
  on:blur={touchStart}
  on:focus={touchEnd} />

<ScenarioValueListener on:change={onValueChange} />

{#each scenario as scene}
  <div style="position: absolute; z-index: {scene.background ? 0 : 1000}">
    <svelte:component
      this={scene.component}
      {...scene.props}
      on:event={({ detail }) => {
        if (detail === 'play') onSceneStart(scene)
      }}
      on:end={() => onSceneEnd(scene)}
      on:end={scene['on:end'] ? scene['on:end'] : () => {}}
      on:event={scene['on:event'] ? scene['on:event'] : () => {}} />
  </div>
{/each}

<TextSlides on:slideend={onSlideEnd} on:slidestart={onSlideStart} />
