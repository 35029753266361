<script context="module">
  export const frames = {
    end: 199,
  }

  export const origin = [50, 30]
</script>

<script>
  import Animation from '../gestures/Animation.svelte'

  export let type, states
</script>

<Animation file="videos/1920/RockOn.mp4" {type} {states} {frames} {origin} on:event on:end />
