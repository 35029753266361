<script context="module">
  export const frames = {
    end: 76,
  }

  export const origin = [30, 50]
</script>

<script>
  import Animation from '../gestures/Animation.svelte'

  export let type, states
</script>

<Animation file="videos/1920/ThumbsUp.mp4" {type} {states} {frames} {origin} on:event on:end />
