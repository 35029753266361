// calculates where a point/vector intersects with it's surrounding rectangle
const calcBorderIntersection = (px, py, angle) => {
  let vx = Number(Math.cos((Math.PI / 180) * angle).toFixed(5))
  let vy = Number(Math.sin((Math.PI / 180) * angle).toFixed(5))
  let tl, tr, tt, tb
  if (vx !== 0) {
    tl = (0 - px) / vx
    tr = (100 - px) / vx
  }
  if (vy !== 0) {
    tt = (0 - py) / vy
    tb = (100 - py) / vy
  }
  const t = [tl, tr, tt, tb].filter((v) => !!v && v > 0).pop()
  return { x: px + t * vx, y: py + t * vy }
}

export default calcBorderIntersection
