<script context="module">
  export const frames = {
    holdstart: 22,
    holdend: 31,
    return: 22,
    end: 44,
  }

  export const origin = [63.17, 10.17]
</script>

<script>
  import Animation from '../gestures/Animation.svelte'

  export let type = 'in-out'
  export let states
  export const start = () => {
    controller.start(0)
  }

  let controller
</script>

<Animation
  file="videos/1920/SingleTapFast.mp4"
  {type}
  {states}
  {frames}
  {origin}
  bind:this={controller}
  on:event
  on:end />
