<script>
  import Tap from '../gestures/Tap.svelte'
  import { tappables, sliderProp } from '../stores'

  const states = [
    { el: $tappables.alt, rotation: -230, action: () => sliderProp.set('fontAltChars') },
  ]
</script>

<Tap {states} type="simple" on:end on:event />
