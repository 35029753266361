<script context="module">
  export const frames = {
    end: 496,
  }

  export const origin = [63, 5]
</script>

<script>
  import Animation from '../gestures/Animation.svelte'

  export let type, states
</script>

<Animation
  file="videos/1920/PointUpAndBounce.mp4"
  {type}
  {states}
  {frames}
  {origin}
  on:event
  on:end />
