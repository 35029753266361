import shuffle from 'lodash/shuffle'

import scenes from '../scenes'
const { RockOn, LoveYou, Okay, ThumbsUp, RaisedFist, VulcanSalute, Victory } = scenes

const emojis = [RockOn, LoveYou, Okay, ThumbsUp, RaisedFist, VulcanSalute, Victory]
let options = []
let played = []

export default () => {
  if (options.length === 0) {
    const lastPlayed = played.length ? played[played.length - 1] : undefined
    while (options && options[0] === lastPlayed) {
      options = shuffle(emojis)
      played = []
    }
  }

  const component = options.shift()
  played.push(component)

  return { type: 'emoji', component: component, background: true }
}
