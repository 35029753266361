import { quintIn } from 'svelte/easing'
import calcBorderIntersection from '../utils/calcBorderIntersection'
import calcDistance from '../utils/calcDistance'

class Animation {
  constructor(states, variables, frames, player) {
    this.states = states
    this.variables = variables
    this.frames = frames
    this.player = player
  }

  async start() {}

  async cancel() {
    if (this.cancelled) return
    this.cancelled = true
    this.player.pause()
    const begin = this.getState(0)
    const { x, y, rotation = 0 } = begin
    const outPosition = {
      ...calcBorderIntersection(x, y, rotation - 270),
      rotation,
    }

    const dist = calcDistance(x, y, outPosition.x, outPosition.y)
    await this.variables.set(outPosition, { duration: dist * 8, easing: quintIn })
    this.player.cancel()
  }

  getState(index) {
    const state = this.states[index]
    if (!state) throw new Error('Animation state not found')
    let { position, el, x, y, rotation } = state

    if (el instanceof HTMLElement || typeof el === 'function') {
      const element = el instanceof HTMLElement ? el : el()

      const { innerWidth, innerHeight } = window
      const { left, top, width, height } = element.getBoundingClientRect()
      x = ((left + width / 2) / innerWidth) * 100
      y = ((top + height / 2) / innerHeight) * 100
    } else if (position && typeof position === 'function') {
      const pos = position()
      x = pos.x
      y = pos.y
    } else if (position) {
      x = position.x
      y = position.y
    }

    return {
      x,
      y,
      rotation: rotation || 0,
    }
  }
}

export default Animation
