<script>
  import Tap from '../gestures/Tap.svelte'
  import { sliders, fontWeight, fontSize, sliderProp, fontAltChars } from '../stores'
  import { cubicOut } from 'svelte/easing'
  import { getContext } from 'svelte'

  let from, el
  export let to = Math.random()

  const interrupted = getContext('interrupted')

  $: {
    el = $sliders[$sliderProp]
  }

  $: values = {
    fontSize: $fontSize,
    fontAltChars: $fontAltChars,
    fontWeight: $fontWeight,
  }

  const states = [
    { el: () => el(), rotation: -130 },
    {
      position: () => {
        // There must be a better way to reference a $variable dynamically
        from = values[$sliderProp]
        const element = el()
        const rect = element.parentElement.parentElement.getBoundingClientRect()
        const x = ((rect.left + to * rect.width) / window.innerWidth) * 100
        return { x, y: (rect.bottom / window.innerHeight) * 100 }
      },
      onUpdate: (progress) => {
        if ($interrupted) return

        const diff = to - from
        if ($sliderProp === 'fontSize') {
          fontSize.set(from + progress * diff)
        } else if ($sliderProp === 'fontWeight') {
          fontWeight.set(from + progress * diff)
        } else {
          fontAltChars.set(from + progress * diff)
        }
      },
      rotation: -110,
      duration: () => {
        const diff = Math.abs(to - from)
        return cubicOut(diff) * 2000
      },
    },
  ]
</script>

<Tap {states} type="in-move-out" on:end on:event />
