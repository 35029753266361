<script>
  import { onMount, createEventDispatcher } from 'svelte'
  import Button from './ui/Button.svelte'

  const dispatch = createEventDispatcher()
  const text = document.querySelector('#info-text')
  let el

  onMount(() => {
    text.style.display = 'block'
    text.style.transform = ''

    el.appendChild(text)
    return () => {
      text.style.display = 'none'
      document.body.appendChild(text)
    }
  })

  const onBuy = () => {
    window.location = 'https://shop.studiomoniker.com/product/gestures-typeface'
  }

  const onClose = () => {
    dispatch('close')
  }
</script>

<style>
  #info-container {
    background: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999999;
    color: var(--black);
  }

  button {
    padding: 0;
    border: 0;
    background: none;
  }

  #header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2vw;
    padding-right: 1vw;
    padding-top: 1.6vw;
    position: sticky;
    top: 0;
    z-index: 999999;
  }

  #header p {
    line-height: 1em;
    letter-spacing: 0.006em;
    margin: 0;
    font-size: var(--button-size);
  }

  #header #close-btn {
    padding: calc(var(--button-size) * 0.35);
    line-height: 0;
    width: calc(var(--button-size) * 1.32);
    height: calc(var(--button-size) * 1.32);
  }

  #close-btn svg {
    width: 100%;
    height: 100%;
  }

  :global(#info-buy-btn-mobile) {
    display: none;
  }

  #header :global(#info-buy-btn) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  #info-container :global(span.alt) {
    font-feature-settings: 'salt';
  }

  #info-container :global(#info-text .case) {
    font-feature-settings: 'case';
  }

  #info-container :global(#info-text) {
    font-size: calc(var(--button-size) * 0.9);
    line-height: 1.33;
    letter-spacing: 0.006em;
    text-align: center;
    margin-top: 5vw;
    padding-bottom: 2.75em;
  }

  #info-container :global(p) {
    max-width: 42vw;
    margin: 1em auto 0;
  }

  #info-container :global(p.mb) {
    margin-bottom: 2.5em;
  }

  #info-container :global(p.mb-2) {
    margin-bottom: 5em;
  }

  #info-container :global(p:last-child) {
    max-width: 60vw;
    font-size: calc(var(--button-size) * 0.85);
    line-height: 1.4;
  }

  #info-container :global(a) {
    text-decoration: none;
    color: var(--black);
    position: relative;
  }

  #info-container :global(a:not(.no-underline):after) {
    content: '';
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.25em;
    height: 0.1em;
    background-image: url('/images/underline-black.svg');
    background-repeat: repeat-x;
  }

  @media (max-width: 768px) {
    #header {
      position: initial;
      padding-top: 3vw;
      padding-left: 4vw;
      padding-right: 3vw;
      align-items: center;
      margin-bottom: 12vw;
    }

    #header p {
      font-size: 6.667vw;
    }

    #header #close-btn {
      padding: calc(var(--button-size) * 0.3);
    }

    #header :global(#info-buy-btn) {
      display: none;
    }

    :global(#info-buy-btn-mobile) {
      display: block;

      position: sticky;
      top: 3vw;
      text-align: center;
      z-index: 999999;
    }

    :global(#info-buy-btn-mobile > button) {
      font-size: 8vw;
    }

    #info-container :global(#info-text) {
      margin-top: 9vw;
      font-size: calc(var(--button-size));
    }

    #info-container :global(p) {
      max-width: 90vw;
    }

    #info-container :global(p:last-child) {
      max-width: 77vw;
      font-size: calc(var(--button-size) * 0.85);
    }

    #info-container :global(#info-text) {
      padding-bottom: 10vw;
    }
  }
</style>

<div id="info-container" bind:this={el}>
  <div id="header">
    <p><span class="alt">G</span>estures by Moniker</p>
    <Button id="info-buy-btn" label="buy typeface" color="blue" on:click={onBuy} />
    <button id="close-btn" type="button" on:click={onClose}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26.051"
        height="26.051"
        viewBox="0 0 26.051 26.051">
        <g transform="translate(-964.035 -47.272)">
          <path
            d="M5105.1,2456.957l23.93-23.93"
            transform="translate(-4140 -2384.694)"
            fill="none"
            stroke="#000"
            stroke-width="3" />
          <path
            d="M0,23.93,23.93,0"
            transform="translate(965.096 72.262) rotate(-90)"
            fill="none"
            stroke="#000"
            stroke-width="3" />
        </g>
      </svg>
    </button>
  </div>
  <div id="info-buy-btn-mobile">
    <Button label="buy typeface" color="blue" on:click={onBuy} />
  </div>
</div>
