<script>
  import { tweened } from 'svelte/motion'
  import { cubicInOut } from 'svelte/easing'

  // import { animations as animStore } from '../stores'
  import GestureCanvas from './GestureCanvas.svelte'
  import Gesture from './Gesture.svelte'
  // import InOut from '../animations/inout'
  import InMoveOut from '../animations/inmoveout'
  // import InHoldOut from '../animations/inholdout'
  import Simple from '../animations/simple'
  import { getContext, onDestroy } from 'svelte'

  export let file
  export let frames
  export let origin
  export let states
  export let type = 'in-out'
  let playing

  const animations = {
    // 'in-out': InOut,
    'in-move-out': InMoveOut,
    // 'in-hold-out': InHoldOut,
    simple: Simple,
  }

  let animation
  let player
  let interrupted = getContext('interrupted')
  let transform = tweened(states[0], { easing: cubicInOut })

  const onEvent = ({ detail }) => {
    if (detail === 'play') {
      animation = new animations[type](states, transform, frames, player)
      animation.start()
    }
  }

  const unsub = interrupted.subscribe((val) => {
    if (val && animation) {
      animation.cancel()
    }
  })

  onDestroy(() => {
    unsub()
  })

  // TODO: remove when going live. Manually triggers scene
  export const start = async (time) => {
    player.play(time)
    animation = new animations[type](states, transform, frames, player)
    animation.start()
  }
</script>

<Gesture transform={$transform}>
  <GestureCanvas
    {file}
    {origin}
    {frames}
    bind:playing
    bind:this={player}
    on:event={onEvent}
    on:event
    on:end />
</Gesture>
