import { get } from 'svelte/store'
import { textSlideIndex } from '../stores'
import request from './request'

const noop = () => {}
const isMuted = window.localStorage.getItem('gestures-muted') === 'true'

class AudioPlayer {
  constructor({ onPlay = noop, onEnd = noop, onError = noop } = {}) {
    this.context = new (window.AudioContext || window.webkitAudioContext)()
    this.onPlay = onPlay
    this.onEnd = onEnd
    this.onError = onError

    this.isPlaying = false
    this.isLoading = false
    this.muted = isMuted

    // Safari/CAF mimetype is "audio/x-caf"
    this.ext = AudioPlayer.supportsMedia('audio/ogg; codecs=opus') ? 'ogg' : 'caf'

    AudioPlayer.unlockAudioContext(this.context).then(() => {
      this.gainNode = this.context.createGain()
      this.gainNode.gain.setValueAtTime(1.0, this.context.currentTime)
      this.gainNode.connect(this.context.destination)
      this.play(get(textSlideIndex) + 1)
    })
  }

  async play(number, offset = 0) {
    this.number = number
    const { ext } = this

    if (this.source) this.source.disconnect(this.gainNode)

    try {
      const buffer = await this.load(`audio/${ext}/${number}.${ext}`)
      this.source = this.context.createBufferSource()
      this.source.connect(this.gainNode)

      this.source.onended = () => {
        if (this.context.currentTime >= buffer.duration - 0.25) {
          this.ended = true
          const wasPlaying = this.isPlaying
          this.isPlaying = false
          if (wasPlaying) this.onEnd()
        }
      }
      this.ended = false
      this.source.buffer = buffer
      this.source.start(0, offset)
      this.startedAt = this.context.currentTime - offset

      this.gainNode.gain.setValueAtTime(this.muted ? 0.00001 : 1.0, this.context.currentTime)

      this.isPlaying = true
      this.onPlay()
    } catch (error) {
      this.onError(error)
    }
  }

  pause() {
    if (!this.ended && this.source) {
      this.isPlaying = false
      this.gainNode.gain.setValueAtTime(this.gainNode.gain.value, this.context.currentTime)
      this.gainNode.gain.exponentialRampToValueAtTime(0.00001, this.context.currentTime + 0.5)
      this.source.stop(this.context.currentTime + 0.5)
      this.resumeTime = this.context.currentTime - this.startedAt
    }
  }

  async resume() {
    if (this.source && !this.ended) {
      await this.play(this.number, this.resumeTime || 0)
      if (!this.muted) {
        this.gainNode.gain.setValueAtTime(this.gainNode.gain.value, this.context.currentTime)
        this.gainNode.gain.exponentialRampToValueAtTime(1.0, this.context.currentTime + 0.5)
      }
    }
  }

  fadeIn() {
    if (!this.muted) {
      this.gainNode.gain.setValueAtTime(this.gainNode.gain.value, this.context.currentTime)
      this.gainNode.gain.exponentialRampToValueAtTime(1.0, this.context.currentTime + 0.5)
    }
  }

  fadeOut() {
    this.gainNode.gain.setValueAtTime(this.gainNode.gain.value, this.context.currentTime)
    this.gainNode.gain.exponentialRampToValueAtTime(0.00001, this.context.currentTime + 0.5)
  }

  load(file) {
    this.isLoading = true
    return new Promise((resolve, reject) => {
      this.xhr = request(file, {
        responseType: 'arraybuffer',
        onload: () => {
          this.isLoading = false
          this.context.decodeAudioData(this.xhr.response, resolve, reject)
        },
        onerror: (error) => {
          console.error(error)
          this.isLoading = false
        },
      })
    })
  }

  cancel() {
    if (this.xhr) this.xhr.abort()
    if (this.isPlaying) this.source.stop()
    this.isLoading = false
  }
}

AudioPlayer.supportsMedia = (mimetype) => {
  var elem = document.createElement('audio')
  if (typeof elem.canPlayType == 'function') {
    var playable = elem.canPlayType(mimetype)
    if (playable.toLowerCase() == 'maybe' || playable.toLowerCase() == 'probably') {
      return true
    }
  }
  return false
}

AudioPlayer.unlockAudioContext = (context) => {
  return new Promise((resolve) => {
    if (context.state !== 'suspended') resolve()
    const b = document.body
    const events = ['touchstart', 'touchend', 'mousedown', 'keydown']
    events.forEach((e) => b.addEventListener(e, unlock, false))
    function unlock() {
      context.resume().then(clean)
      resolve()
    }
    function clean() {
      events.forEach((e) => b.removeEventListener(e, unlock))
    }
  })
}

export default new AudioPlayer()
