import { get } from 'svelte/store'

import SwipeUp from '../gestures/SwipeUp.svelte'
import SwipeDown from '../gestures/SwipeDown.svelte'
import SwipeLeft from '../gestures/SwipeLeft.svelte'
import SwipeRight from '../gestures/SwipeRight.svelte'

import { swipe, textSlideIndex } from '../stores'

const GESTURES = {
  up: {
    component: SwipeUp,
    rotation: -90,
    duration: Math.min(window.innerWidth * 1.5, 1200),
  },
  down: {
    component: SwipeDown,
    rotation: 90,
    duration: Math.min(window.innerWidth * 1.5, 1200),
  },
  left: {
    component: SwipeLeft,
    rotation: 0,
    duration: window.innerWidth * 3,
  },
  right: {
    component: SwipeRight,
    rotation: 180,
    duration: window.innerWidth * 2,
  },
}

class SwipeScenario {
  constructor() {
    this.started = false

    this.swipeAction = () => {
      let s = { ...get(swipe) }
      s.duration.in = GESTURES[this.dir].duration
      swipe.set(s)
      textSlideIndex.set(get(textSlideIndex) + 1)
    }

    this.onEvent = ({ detail }) => {
      if (detail === 'play') {
        this.started = true
      }
    }
  }

  next() {
    if (this.started) return

    const options = ['up', 'down', 'left', 'right']
    this.dir = options[Math.floor(Math.random() * options.length)]
    const { duration, ...gesture } = GESTURES[this.dir]
    let s = { ...get(swipe), ...gesture }
    s.duration.out = duration
    s.dir = this.dir
    swipe.set(s)

    return {
      component: gesture.component,
      background: true,
      props: {
        type: 'simple',
        states: [
          { position: { x: 50, y: 50 }, rotation: get(swipe).rotation, action: this.swipeAction },
        ],
      },
      'on:end': () => (this.started = false),
      'on:event': this.onEvent,
    }
  }
}

export default SwipeScenario
