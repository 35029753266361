<script>
  import Tap from '../gestures/Tap.svelte'
  import { tappables, sliderProp } from '../stores'

  const states = [
    { el: $tappables.weight, rotation: -200, action: () => sliderProp.set('fontWeight') },
  ]
</script>

<Tap {states} type="simple" on:end on:event />
