<script context="module">
  export const frames = {
    return: 31,
    end: 58,
  }

  export const origin = [47, 27]
</script>

<script>
  import Animation from '../gestures/Animation.svelte'
  export let type, states
</script>

<Animation file="videos/1920/SwipeDown.mp4" {type} {states} {frames} {origin} on:event on:end />
