import Animation from './animation'
import get_interpolator from '../utils/get_interpolator'

class InMoveOut extends Animation {
  async start() {
    const { states, variables, frames, player } = this
    const inDuration = (frames['return'] / 25) * 1000
    const outDuration = ((frames['end'] - frames['return']) / 25) * 1000

    const from = this.getState(0)
    const to = this.getState(1)

    await variables.set(from, { duration: 0 })
    if (this.cancelled) return
    await variables.set(from, { duration: inDuration })

    if (this.cancelled) return

    player.pause()
    const { duration } = states[1]

    await variables.set(to, {
      duration: typeof duration === 'function' ? duration() : duration,
      interpolate: (a, b) => {
        const keys = Object.keys(b)
        const interpolators = {}

        keys.forEach((key) => {
          interpolators[key] = get_interpolator(a[key], b[key])
        })

        return (t) => {
          if (states[1].onUpdate) states[1].onUpdate(t)
          const result = {}
          keys.forEach((key) => {
            result[key] = interpolators[key](t)
          })
          return result
        }
      },
    })

    if (this.cancelled) return
    player.play()
    await variables.set(to, { duration: outDuration })
  }
}

export default InMoveOut
