<script>
  import { get } from 'svelte/store'
  import Tap from '../gestures/Tap.svelte'
  import { tappables, darkMode } from '../stores'

  const states = [
    { el: $tappables.dark, rotation: -170, action: () => darkMode.set(!get(darkMode)) },
  ]
</script>

<Tap {states} type="simple" on:end on:event />
