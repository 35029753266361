import { writable } from 'svelte/store'
import texts from './texts'

const slide = Number(new URLSearchParams(window.location.search).get('slide'))
const startSlide = slide
export const textSlideIndex = writable(startSlide)

const size = texts[startSlide].Size
const weight = texts[startSlide].Weight
const dark = texts[startSlide].Dark
const alt = texts[startSlide].Alt

export const fontSize = writable(size)
export const fontWeight = writable(weight)
export const fontAltChars = writable(alt)
export const darkMode = writable(dark)
export const sliderProp = writable('fontAltChars')

const getElById = (id) => () => document.querySelector(`#${id}`)

export const tappables = writable({
  buy: getElById('buy-btn'),
  info: getElById('info-btn'),
  size: getElById('size-btn'),
  weight: getElById('weight-btn'),
  alt: getElById('alt-btn'),
  dark: getElById('dark-btn'),
})

export const sliders = writable({
  fontSize: getElById('size-slider-thumb'),
  fontWeight: getElById('weight-slider-thumb'),
  fontAltChars: getElById('alt-slider-thumb'),
})

export const handScale = writable(0.84) //temp value

textSlideIndex.subscribe((val) => {
  const { Size, Weight, Dark, Alt } = texts[val]
  fontSize.set(Size)
  fontWeight.set(Weight)
  fontAltChars.set(Alt)
  darkMode.set(Dark)
})

export const swipe = writable({
  direction: undefined,
  duration: {
    in: 0,
    out: 0,
  },
})

export const editing = writable(false)
export const edited = writable(false)

export const showInfo = writable(false)
