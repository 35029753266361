<script>
  import { fontWeight, fontSize, sliderProp, fontAltChars } from '../stores'
  import DarkMode from './DarkMode.svelte'
  import Slider from './Slider.svelte'

  import SliderSelector from './SliderSelector.svelte'
  import WeightDropdown from './WeightDropdown.svelte'
</script>

<style>
  .dropdown {
    position: absolute;
    top: 1.6vw;
    left: 1.6vw;
    z-index: 999;
  }

  .bottom {
    letter-spacing: 0.02em;
    position: absolute;
    z-index: 999;
    bottom: 3vw;
    left: 4.45vw;
    right: 4.45vw;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .slider {
    width: 45vw;
    margin-left: 1.4vw;
  }

  @media (max-width: 768px) {
    .dropdown {
      top: 3vw;
      left: 3vw;
    }

    .bottom {
      flex-wrap: wrap;
      bottom: 5.7vw;
    }

    .slider {
      width: 80vw;
      margin: 0 auto;
      margin-top: 6.2vw;
    }
  }
</style>

<div class="dropdown">
  <WeightDropdown />
</div>

<div class="bottom">
  <DarkMode />

  <SliderSelector bind:value={$sliderProp} />

  <span class="slider">
    {#if $sliderProp === 'fontWeight'}
      <Slider id="weight-slider" bind:value={$fontWeight} step={0.0001} />
    {:else if $sliderProp === 'fontSize'}
      <Slider id="size-slider" bind:value={$fontSize} step={0.0001} />
    {:else}
      <Slider id="alt-slider" bind:value={$fontAltChars} step={0.0001} />
    {/if}
  </span>
</div>
