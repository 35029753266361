import Animation from './animation'

class Simple extends Animation {
  async start() {
    const { states, variables, frames } = this
    const inDuration = (frames['return'] / 25) * 1000

    const state = this.getState(0)
    await variables.set(state, { duration: 0 })
    if (this.cancelled) return
    await variables.set(state, { duration: inDuration })
    if (this.cancelled) return
    if (states[0].action) states[0].action()
  }
}

export default Simple
