<script context="module">
  export const frames = {
    return: 38,
    end: 71,
  }

  export const origin = [37, 18]
</script>

<script>
  import Animation from '../gestures/Animation.svelte'

  export let type, states
</script>

<Animation file="videos/1920/SwipeRight.mp4" {type} {states} {frames} {origin} on:event on:end />
