const request = (url, { onload = () => {}, onerror = () => {}, responseType = 'blob' }) => {
  const request = new XMLHttpRequest()
  request.onload = onload
  request.onerror = onerror
  request.open('GET', url)
  request.responseType = responseType
  request.send()
  return request
}

export default request
