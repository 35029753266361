<script>
  import { MDCSlider } from '@material/slider'
  import { onMount, onDestroy } from 'svelte'
  import { get_current_component } from 'svelte/internal'
  import { forwardEventsBuilder } from '@smui/common/forwardEvents.js'
  import { exclude } from '@smui/common/exclude.js'
  import { document } from 'lodash/_freeGlobal'

  const forwardEvents = forwardEventsBuilder(get_current_component(), [
    'MDCSlider:input',
    'MDCSlider:change',
  ])

  export let id = ''
  export let min = 0
  export let max = 1
  export let step = 0
  export let value = null
  export let tabindex = '0'
  let element
  let slider

  $: if (slider && slider.min !== min) {
    slider.min = min
  }
  $: if (slider && slider.max !== max) {
    slider.max = max
  }
  $: if (slider && slider.step !== step) {
    slider.step = step
  }
  $: if (slider && slider.value !== value) {
    slider.value = value
  }
  onMount(() => {
    slider = new MDCSlider(element)
  })
  onDestroy(() => {
    slider && slider.destroy()
  })
  function handleChange() {
    value = slider.value
  }

  // fixes bug where slider thumb gets stuck on pointer when pointer leaves screen
  const onMouseLeave = () => {
    if (slider) {
      new Array('pointerup', 'mouseup', 'touchend').forEach((e) => {
        var event = document.createEvent('MouseEvents')
        event.initEvent(e, true, true)
        document.body.dispatchEvent(event)
      })
    }
  }
</script>

<style>
  .mdc-slider {
    position: relative;
    width: 100%;
    height: 1.5vw;
    cursor: pointer;
    touch-action: pan-x;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .mdc-slider .mdc-slider__track {
    background-color: #287af2;
  }
  .mdc-slider .mdc-slider__track-container {
    background-color: #c1c1c1;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }

  .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__thumb {
    fill: #fff;
    stroke: #c1c1c1;
  }
  .mdc-slider:focus {
    outline: none;
  }
  .mdc-slider__track-container {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 0.6vw;
    border-radius: 1vw;
    overflow: hidden;
  }
  .mdc-slider__track {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: left top;
    will-change: transform;
    border-radius: 100px;
  }
  .mdc-slider__track-marker-container {
    display: flex;
    margin-right: 0;
    visibility: hidden;
  }
  .mdc-slider__thumb-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 2.9vw;
    height: 100%;
    user-select: none;
    will-change: transform;
  }
  .mdc-slider__thumb {
    position: absolute;
    left: 0;
    top: -0.4vw;
    width: 2.9vw;
    /* transform: scale(0.571); */
    stroke-width: 0.9;
  }
  /* .mdc-slider--active .mdc-slider__thumb {
    transform: scaleX(1);
  } */

  @media (max-width: 768px) {
    .mdc-slider {
      --scale: 3.3;
      height: calc(var(--scale) * 1.5vw);
    }
    .mdc-slider__track-container {
      height: calc(var(--scale) * 0.6vw);
      border-radius: calc(var(--scale) * 1vw);
    }
    .mdc-slider__thumb-container {
      width: calc(var(--scale) * 2.9vw);
    }
    .mdc-slider__thumb {
      top: calc(var(--scale) * -0.4vw);
      width: calc(var(--scale) * 2.9vw);
    }
  }
</style>

<svelte:body on:mouseleave={onMouseLeave} />

<div
  bind:this={element}
  use:forwardEvents
  class="mdc-slider"
  role="slider"
  aria-valuemin={min}
  aria-valuemax={max}
  aria-valuenow={value}
  {...step === 0 ? {} : { 'data-step': step }}
  {tabindex}
  on:MDCSlider:input={handleChange}
  {...exclude($$props, [
    'use',
    'class',
    'disabled',
    'discrete',
    'displayMarkers',
    'min',
    'max',
    'step',
    'value',
    'tabindex',
  ])}>
  <div class="mdc-slider__track-container">
    <div class="mdc-slider__track" />
  </div>
  <div class="mdc-slider__thumb-container">
    <svg id={id && `${id}-thumb`} class="mdc-slider__thumb" viewBox="0 0 21 21">
      <circle cx="10.5" cy="10.5" r="7.875" />
    </svg>
  </div>
</div>
