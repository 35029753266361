<script>
  import { darkMode } from '../stores'

  const toggle = () => {
    $darkMode = !$darkMode
  }
</script>

<style>
  .mode {
    display: flex;
    flex-direction: row;
    font-size: var(--label-size);
    top: 0.3em;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  :root {
    --slider-size: var(--label-size);
  }

  .bg {
    position: relative;
    height: var(--slider-size);
    width: calc(var(--slider-size) * 1.9);
    background: #c1c1c1;
    border-radius: calc(var(--slider-size) * 2);
    line-height: 0;
    margin-left: 1.5vw;
    top: 0.4vw;
    transition: background 100ms ease-out;
  }

  .circle {
    position: absolute;
    width: calc(var(--slider-size) * 1);
    fill: white;
    left: 0;
    transition: left 200ms ease-out;
  }

  .mode :global(.dark) {
    background: #287af2;
  }

  .mode :global(.dark .circle) {
    left: calc(100% - var(--slider-size));
  }

  @media (max-width: 768px) {
    :root {
      --slider-size: calc(var(--label-size) * 1.1);
    }
  }
</style>

<div class="mode" on:click={toggle}>
  Dark mode

  <div id="dark-btn" class="bg" class:dark={$darkMode}>
    <svg class="circle" viewBox="0 0 21 21">
      <circle cx="10.5" cy="10.5" r="7.875" />
    </svg>
  </div>
</div>
